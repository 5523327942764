import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { graphql } from 'gatsby'

import { Row, Col } from 'react-flexa'
import Title from '../common/Title'
import { useAppContext } from '../../context/AppContext'
import ProjectsGrid from '../common/ProjectsGrid'

const Projects = ({
  data: {
    projects: { edges: projects },
  },
}) => {
  const { setColorMode } = useAppContext()

  useEffect(() => {
    setColorMode(0)
  }, [setColorMode])

  // useEffect(() => {
  //   setImage(featuredImage)
  // }, [featuredImage, setImage])
  return (
    <Container>
      <StyledRow justifyContent="flex-start">
        <ProjectsGrid projects={projects} />
        <TitleCol display="flex">
          <TitleContainer>
            <Title as="h1">Projects</Title>
          </TitleContainer>
        </TitleCol>
      </StyledRow>
    </Container>
  )
}

Projects.propTypes = {
  data: PropTypes.object,
}

export default Projects

const Container = styled.div(
  ({ theme }) => css`
    width: 100%;
    position: relative;
    min-height: 100%;
  `,
)

const TitleContainer = styled.div(
  ({ theme }) => css`
    position: absolute;
    transform: translate(0, 35%);
    z-index: 12;
    right: 0;
    bottom: 0;
    ${theme.media.lg`
      
    `}
  `,
)

const StyledRow = styled(Row)(
  ({ theme }) => css`
    overflow: hidden;
    height: 100%;
  `,
)

const TitleCol = styled(Col)(
  ({ theme }) => css`
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 2;
    pointer-events: none;
  `,
)

export const query = graphql`
  query Projects {
    projects: allDatoCmsProject {
      edges {
        node {
          ... on DatoCmsProject {
            path
            types {
              projectType
            }
            subTitle
            title
            media {
              customData
              fixed(width: 1024, imgixParams: { fm: "jpg", auto: "compress" }) {
                src
              }
            }
          }
        }
      }
    }
  }
`
