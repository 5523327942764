import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import styled, { css, useTheme } from 'styled-components'
import { motion } from 'framer-motion'
import { Col, Row } from 'react-flexa'
import { isEqual, get } from 'lodash'
import { Link } from 'gatsby'
import { backgroundTransition, projectImageTransition } from '../../style/animation'
import { useAppContext } from '../../context/AppContext'
import Text from './Text'
import LazyImage from './LazyImage'

const ProjectsGrid = ({ projects }) => {
  const imagesLoaded = useRef([])
  const {
    media: { isLarge },
    image: { image, imageLocation },
  } = useAppContext()
  const theme = useTheme()
  let pattern = theme.layout.pattern.xs
  if (isLarge) pattern = theme.layout.pattern.lg

  const cols = pattern[0].length

  return (
    <Background transition={backgroundTransition} animate={{ backgroundColor: theme.color.background }}>
      <StyledRow justifyContent="flex-start" alignContent="flex-start">
        {projects.map(({ node: project }, i) => {
          const patternRow = pattern[Math.floor(i / cols) % pattern.length]
          const patternCol = patternRow[i % cols]

          const img = get(project, 'media[0].fixed.src', false)
          return (
            <StyledCol key={project.title + i} xs={6} lg={4}>
              <Link to={`/${project.path}`}>
                <ColorBlock
                  transition={{ ...projectImageTransition, delay: i * 0.15 }}
                  animate={{ opacity: patternCol, backgroundColor: theme.color.primary }}
                />
                <ProjectTitle
                  xs={16}
                  light
                  color="#ffffff"
                  uppercase
                  spacing={2}
                  transition={{ ...projectImageTransition, delay: i * 0.35 }}
                  animate={{ opacity: 0.6 }}
                >
                  <h2>{project.title}</h2>
                </ProjectTitle>
                {!!img && <LazyImage src={img} opacityMod={patternCol} i={i} />}
              </Link>
            </StyledCol>
          )
        })}
      </StyledRow>
    </Background>
  )
}

ProjectsGrid.propTypes = {}

export default ProjectsGrid

const ProjectTitle = styled(Text.motion)(
  ({ theme }) => css`
    position: absolute;
    bottom: ${theme.space(1)};
    left: ${theme.space(1)};
    opacity: 0;
  `,
)

const ImagePlate = styled(motion.img)`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: saturate(0);
    position: absolute;
    opacity: 0;
  `}
`

const ColorBlock = styled(motion.div)`
  ${({ theme }) => css`
    //pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
  `}
`

const Background = styled(motion.div)`
  ${({ theme }) => css`
    width: 100%;
    z-index: 1;
    min-height: 100%;
    background: black;
  `}
`

const StyledRow = styled(Row)`
  ${({ theme }) => css`
    overflow: hidden;
    height: 100%;
  `}
`

const StyledCol = styled(Col)`
  ${({ theme, height }) => css`
    height: ${height || '33vh'};
    max-height: 33vh;
    position: relative;

    ${theme.media.lg`
      height: ${height || '50vh'};
      max-height: 50vh;
    `}
  `}
`
