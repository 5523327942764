import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { projectImageTransition } from '../../style/animation'
import { motion } from 'framer-motion'

const LazyImage = ({ opacityMod, src, i }) => {
  const imageRef = useRef(null)
  const [imageLoaded, setImageLoaded] = useState(0)
  useEffect(() => {
    if (imageRef.current) {
      imageRef.current.onload = () => {
        setImageLoaded(1)
      }
    }
  }, [])

  return (
    <ImagePlate
      ref={imageRef}
      src={src}
      transition={{ ...projectImageTransition }}
      initial={{ transition: { delay: i * 0.25 } }}
      animate={{ opacity: imageLoaded * (0.2 - opacityMod) }}
      whileTap={{ opacity: imageLoaded * (1 - opacityMod), transition: { delay: 0 } }}
      whileHover={{ opacity: imageLoaded * (1 - opacityMod), transition: { delay: 0 } }}
    />
  )
}

LazyImage.propTypes = {}

export default LazyImage

const ImagePlate = styled(motion.img)`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: saturate(0);
    position: absolute;
    opacity: 0;
  `}
`
